import EventEmitter from 'events'
import { Howl } from 'howler'

/**
 * Audio driver interface.
 * This is low-level and should be simple as possible
 *
 * @param  {object} config The global audio config
 * @return {object}        The driver API
 */
module.exports = function (config) {
  const debug = require('debug')('driver:html5:' + config.id)

  let Audio = null
  let Status = {
    input: null,
    state: 'unloaded',
    volume: null,
    duration: null,
    position: null
  }
  const Driver = {
    event: new EventEmitter()
  }

  Driver.load = function (input) {
    debug('Driver.load', input)
    Status.input = input

    return new Promise((resolve, reject) => {
      Audio = new Howl({
        src: [input],
        // volume: 0,
        // html5: true,
        preload: true,
        onload: () => {
          debug('Howl.onload')
          Status.state = 'loaded'
          resolve()
          Driver.event.emit('load')
        },
        onloaderror: (e) => {
          debug('Howl.onloaderror')
          console.log(e)
          Status.state = 'loadError'
          Driver.event.emit('loadError', e)
          reject(e)
        },
        onplay: () => {
          debug('Howl.onplay')
          Status.state = 'playing'
          Driver.event.emit('play')
        },
        onstop: () => {
          debug('Howl.onstop')
          Status.state = 'stopped'
          Driver.event.emit('stop')
        },
        onpause: () => {
          debug('Howl.onpause')
          Status.state = 'paused'
          Driver.event.emit('pause')
        },
        onend: () => {
          debug('Howl.onend')
          Status.state = 'end'
          Driver.event.emit('end')
        }
        // onmute: Driver.Report.mute,
        // onseek: Driver.Report.seek
      })

      // Audio.once('load', function () {
      //   debug('Howl.once.load')
      //   resolve()
      // })
    })
  }

  Driver.unLoad = () => {
    debug('Driver.unLoad()')
    Audio.unload()
    Status = {
      input: null,
      state: 'unloaded'
    }
    Driver.event.emit('unLoad')
  }

  Driver.status = () => {
    let seek = Audio.seek() || 0
    seek = Math.round(seek)

    const status = Object.assign(Status, {
      volume: Audio.volume(),
      duration: Audio.duration(),
      position: seek
    })

    return status
  }

  Driver.play = () => {
    debug('Driver.play()')
    Audio.play()
  }

  Driver.pause = () => {
    debug('Driver.pause()')
    Audio.pause()
  }

  Driver.stop = () => {
    debug('Driver.stop()')
    Audio.stop()
  }

  Driver.volume = (value) => {
    debug('Driver.volume()')
    Audio.volume(value)
  }

  Driver.seek = (secondToJump) => {
    debug('Driver.seek()')
    Audio.seek(secondToJump)
  }

  return Driver
}
