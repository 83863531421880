import Config from '../config'
import { Html5PlayerConnected } from '../player'
import { Store } from 'sdk-common'
import io from 'socket.io-client'

// New player instance
const playerCoreConfig = {}
const playerConnected = Html5PlayerConnected(playerCoreConfig)

// Persistent store
const store = new Store({
  namespace: 'gmPlayer',
  store: window.localStorage
})

// UI settings
const iframePlayerUI = document.getElementById('playerUI')
const iframePlayerUIUrl = Config.ui.server

const state = {
  loading: true,
}

const exposedAPI = async (method, params) => {
  switch (method) {
    case 'changeChannel':
      state.loading = true
      updateStateOnUI()
      await playerConnected.changeChannel(params)
      state.loading = false
      break
    case 'logout':
      state.loading = true
      updateStateOnUI()
      await store.delete('login')
      playerConnected.account_logout()
      socketServer.disconnect()
      state.loading = false
      break
    case 'login':
      state.loading = true
      updateStateOnUI()
      await store.set('login', params)
      await boot(params)
      state.loading = false
      break
    case 'player_stop':
      playerConnected.stop()
      break
    case 'player_play':
      playerConnected.play()
      break
    case 'player_pause':
      playerConnected.pause()
      break
    case 'player_previous':
      playerConnected.previous()
      updateStateOnUI()
      break
    case 'player_next':
      playerConnected.next()
      updateStateOnUI()
      break
    default:
      console.log({ method, params });
      throw new Error('Method not found!')
      break
  }
}

const boot = async (login) => {
  state.loading = true
  try {
    const license = await playerConnected.account_login(login)
    remoteChannelToConnect = license.apikey
    socketServer.connect()
  } catch (e) {
    console.error(e)
    // await store.delete('login')
    state.loading = false
    return
  }

  await playerConnected.playDefaultChannel()
  state.loading = false
}

const updateStateOnUI = () => {
  const status = playerConnected.account_status()
  status.loading = state.loading

  const payload = JSON.stringify({
    method: 'updateState',
    params: status
  })
  
  iframePlayerUI.contentWindow.postMessage(payload, '*')
  socketServer.emit('updateState', status)
}

// Start listening for new messages
window.addEventListener('message', async (event) => {
  if (!event.data) {
    return
  }
  
  const data = JSON.parse(event.data)
  
  await exposedAPI(data.method, data.params)
}, false)


async function run() {
  const loginData = await store.get('login')
  if (loginData) {
    await boot(loginData)
  } else {
    state.loading = false
  }

  // Start updating UI
  setInterval(updateStateOnUI, 1000)
}

let remoteChannelToConnect
let socketServer = io(Config.remote.server, { autoConnect: false })
socketServer.on('connect', async function () {
  const loginData = await store.get('login')
  socketServer.emit('serveRemote', remoteChannelToConnect)
})

socketServer.on('disconnect', function () { })
socketServer.on('rpc', function (method, params) {
  console.log('rpc', { method, params });
  exposedAPI(method, params)
})


// Load UI
iframePlayerUI.src = iframePlayerUIUrl

// Start player
run()